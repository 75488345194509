<template>
    <!-- <span class="chip">
        <span class="point big" :class="item==1 ? 'bg-success' : 'bg-warning'"></span>
    </span> -->
    <div>
        {{items}}
        {{params}}
    </div>
</template>
<script>
export default {

  data () {
    return {
      item: '',
      items:{}
    }
   },
   beforeMount() {
       this.item = this.params.value;
       this.items = this.params;
   }
};
</script>